import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { ExSwitch } from "../../components/ExSwitch";
import { model_requisicao_campos } from "../../models";


export const RequisicaoCampos = () => {
    const columns = [
        { field: 'reqcamposequencia', header: 'Sequência' },
        { field: 'reqcamponome', header: 'Nome' },
        { field: 'statdescricao', header: 'A partir do status' },
        { field: 'reqcampoobrigatorio', header: 'Obrigatório', body: (e: any) => <ExSwitch checked={e.reqcampoobrigatorio === 1} /> },
        { field: 'reqcampoativo', header: 'Ativo' }
    ];

    return (
        <ConsultaPadrao model={model_requisicao_campos} columns={columns} sortField="reqcamposequencia" />
    )
}