import { Card } from "react-bootstrap";
import { CardTable, Header, Icon, Title } from "../../components/ConsultaPadrao/components";
import { isMobileDevice, toastError } from "../../commons/utils";
import { model_grupo } from "../../models";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { ExCadastroPadrao } from "../../components/ExCadastroPadrao";
import { Container, RowMain, RowWrapper, RowChild, ExpandButton, GrupoDescription } from "./styles";
import { ExButton } from "../../components/ExButton";

export const Grupo = () => {
    const auth = useAuth();

    const refCadastroPadrao = useRef<any>();

    const [data, setData] = useState<any[]>([]);

    async function refresh() {
        await auth.get(model_grupo.api_get)
            .then((response: any) => {
                const expandedData = response.data.map((grupo: any) => ({
                    ...grupo,
                    expandido: true,
                }));
                setData(expandedData);
                auth.refreshGrupos();
            })
            .catch((error: any) => {
                toastError(error);
            })
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleExpandir = (gruid: number) => {
        setData(prevData =>
            prevData.map(grupo => grupo.gruid === gruid ? { ...grupo, expandido: !grupo.expandido } : grupo)
        );
    };

    const renderizarGrupo = (grupo: any) => {
        const childs = data.filter(g => g.gruidpai === grupo.gruid);
        const haschilds = childs.length > 0;

        return (
            <RowMain key={grupo.gruid}>
                <RowWrapper>
                    {haschilds && (
                        <ExpandButton
                            onClick={() => toggleExpandir(grupo.gruid)}
                            iconcenter={grupo.expandido ? <IoIosArrowUp /> : <IoIosArrowForward />}
                        />
                    )}
                    <GrupoDescription haschilds={haschilds}>
                        {grupo.grudescricao}
                    </GrupoDescription>
                </RowWrapper>

                {haschilds && (
                    <RowChild style={{ maxHeight: grupo.expandido ? '1000px' : 0 }}>
                        {childs.map(child => renderizarGrupo(child))}
                    </RowChild>
                )}
            </RowMain>
        );
    };

    return (
        <>
            <CardTable className={isMobileDevice() ? "m-3 mb-4 mt-3" : "m-4 mb-4"}>
                <Card.Body className={isMobileDevice() ? "p-1" : "p-2"}>
                    <Header>
                        <Title>
                            <Icon>{model_grupo.icon}</Icon>
                            {model_grupo.name}
                        </Title>
                        {model_grupo.base && <ExButton icon={<FaPlus />} onClick={() => refCadastroPadrao.current.openModal()}>Novo</ExButton>}
                    </Header>
                    <Container>
                        {data.filter(grupo => grupo.gruidpai === null).map(grupo => renderizarGrupo(grupo))}
                    </Container>
                </Card.Body>
            </CardTable>
            {model_grupo.base && (
                <ExCadastroPadrao ref={refCadastroPadrao} model={model_grupo} success={() => refresh()} />
            )}
        </>
    );
};
