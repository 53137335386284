import { Divider, Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { formatDecimal, formatPercentColumn, isMobileDevice, toastError } from "../../../commons/utils";
import BarChart from "../../../charts/Bar";
import { DataTable } from "../../../components/DataTable";
import { Columns } from "..";
import { ExSelect } from "../../../components/ExSelect";
import { model_plantio } from "../../../models";
import { GroupBox } from "../../../components/GroupBox";
import { ExCheckBox } from "../../../components/ExCheckBox";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";

export const DashClassificacoes = forwardRef((props: any, ref) => {
    const auth = useAuth();

    const refClassificacoes = useRef<any>();

    const refPlantio = useRef<any>();

    const [originData, setOriginData] = useState<any[]>([]);
    const [originDataEntrada, setOriginDataEntrada] = useState<any[]>([]);
    const [movClassificacao, setMovClassificacao] = useState<any[]>([]);
    const [viewPecent, setViewPercent] = useState<any>(false);

    useImperativeHandle(ref, () => ({
        setData
    }));

    useEffect(() => {
        if (originData && originData.length > 0)
            populateChart(originData, props?.cultura?.descricao, refPlantio.current.getValue());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewPecent]);

    async function changeCultura() {
        try {
            const response: any = await auth.get(model_plantio.api_get + '?cultid=' + props?.cultura?.id);
            const formattedData = response.data.map((item: any) => ({ value: item.plid, label: item.pldescricao }));

            await refPlantio.current.setCustomOptions(formattedData);
        } catch (error) {
            toastError(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (props?.cultura?.id) {
                refPlantio.current.setCustomValue(null);
                await changeCultura();
            }

            populateChart(originData, props?.cultura?.descricao, refPlantio?.current?.getValue());
            populateGrid(originDataEntrada, originData, props?.cultura?.descricao);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.cultura]);

    function populateChart(_data: any, cultura?: any, plantio?: any) {
        if (cultura)
            _data = _data.filter((item: any) => item.cultdescricao === cultura);

        if (plantio && plantio.length > 0) {
            const _plantios = new Set(plantio.map((item: any) => item.value));
            _data = _data.filter((item: any) => _plantios.has(item.plid));
        }

        const groupClassificados = _data.reduce((acc: any, item: any) => {
            const key = item.culttamdescricao;
            if (!acc[key]) {
                acc[key] = { culttamdescricao: key, totalembalagem: 0, culttamsequencia: item.culttamsequencia };
            }
            acc[key].totalembalagem += item.totalembalagem;
            return acc;
        }, {});

        const _classificacoes = Object.values(groupClassificados).sort((a: any, b: any) => {
            if (a.culttamsequencia < b.culttamsequencia) return -1;
            if (a.culttamsequencia > b.culttamsequencia) return 1;
            return 0;
        });

        const tamanhos = _classificacoes.map((item: any) => parseFloat(item.totalembalagem.toFixed(2)));
        const descricoes = _classificacoes.map((item: any) => item.culttamdescricao);

        if (viewPecent) {
            const totalSum = tamanhos.reduce((sum: number, item: number) => sum + item, 0);
            const porcentagens = tamanhos.map((item: number) => parseFloat(((item / totalSum) * 100).toFixed(2)));

            refClassificacoes?.current.setOptions(descricoes, 100, '% Classificadas por Tamanho', true);
            refClassificacoes?.current.setSeries([{ name: '% Classificadas por Tamanho', data: porcentagens }]);
        } else {
            const getNextMultipleOf2000 = (value: number) => Math.ceil(value / 2000) * 2000;

            refClassificacoes?.current.setOptions(descricoes, getNextMultipleOf2000(Math.max(...tamanhos)), 'Embalagens Classificadas por Tamanho');
            refClassificacoes?.current.setSeries([{ name: 'Embalagens Classificadas por Tamanho', data: tamanhos }]);
        }
    }

    function populateGrid(_dataEntrada: any, _data: any, cultura?: any) {
        if (cultura) {
            _data = _data.filter((item: any) => item.cultdescricao === cultura);
            _dataEntrada = _dataEntrada.filter((item: any) => item.cultdescricao === cultura);
        }

        const groupEntrada = _dataEntrada.reduce((acc: any, item: any) => {
            const key = item.pldescricao;
            if (!acc[key]) {
                acc[key] = { pldescricao: key, totalembentrada: 0, totalpesentrada: 0 };
            }
            acc[key].totalembentrada += item.totalembalagem;
            acc[key].totalpesentrada += item.totalpesagem;
            return acc;
        }, {});

        const groupClassificacao = _data.reduce((acc: any, item: any) => {
            const key = item.pldescricao;
            if (!acc[key]) {
                acc[key] = { pldescricao: key, totalembclassificada: 0, totalpesclassificada: 0 };
            }
            acc[key].totalembclassificada += item.totalembalagem;
            acc[key].totalpesclassificada += item.totalpesagem;
            return acc;
        }, {});

        const combinedData = Object.values(groupEntrada).map((entrada: any) => {
            const classificacao = groupClassificacao[entrada.pldescricao] || { totalembclassificada: 0 };

            const totalembentrada = entrada.totalembentrada;
            const totalpesentrada = entrada.totalpesentrada;
            const totalembclassificada = classificacao.totalembclassificada;
            const totalpesclassificada = classificacao.totalpesclassificada;
            const percentperdaemb = totalembentrada === 0 ? 0 : ((totalembentrada - totalembclassificada) / totalembentrada) * 100;
            const percentperdapes = totalpesentrada === 0 ? 0 : ((totalpesentrada - totalpesclassificada) / totalpesentrada) * 100;

            return {
                pldescricao: entrada.pldescricao,
                totalembentrada,
                totalpesentrada,
                totalembclassificada,
                totalpesclassificada,
                percentperdaemb,
                percentperdapes
            };
        });

        const sortedPlantio = combinedData.sort((a: any, b: any) => {
            if (a.pldescricao < b.pldescricao) return -1;
            if (a.pldescricao > b.pldescricao) return 1;
            return 0;
        });

        setMovClassificacao(sortedPlantio);
    }

    function setData(_dataEntrada: any, _data: any) {
        setOriginData(_data);
        setOriginDataEntrada(_dataEntrada);

        if (_data && _data.length > 0)
            props.onChangeCultura({ id: _data[0].cultid, descricao: _data[0].cultdescricao });
        else {
            populateChart(_data);
            populateGrid(_dataEntrada, _data);
        }
    }

    const columns = [
        { field: 'pldescricao', header: 'Plantio' },
        { field: 'totalembentrada', header: 'Embalagens Entrada', body: (e: any) => formatDecimal(e.totalembentrada), dataType: 'numeric' },
        { field: 'totalembclassificada', header: 'Embalagens Classificadas', body: (e: any) => formatDecimal(e.totalembclassificada), dataType: 'numeric' },
        { field: 'percentperdaemb', header: '% Perda Embalagem', body: (e: any) => formatPercentColumn(e.percentperdaemb), dataType: 'numeric' },
        { field: 'totalpesentrada', header: 'Pesagem Entrada', body: (e: any) => formatDecimal(e.totalpesentrada), dataType: 'numeric' },
        { field: 'totalpesclassificada', header: 'Pesagem Classificadas', body: (e: any) => formatDecimal(e.totalpesclassificada), dataType: 'numeric' },
        { field: 'percentperdapes', header: '% Perda Pesagem', body: (e: any) => formatPercentColumn(e.percentperdapes), dataType: 'numeric' }
    ];

    async function changePlantio(selected: any, data: any) {
        populateChart(originData, props?.cultura?.descricao, selected)
    }

    return (
        <>
            <Grid container spacing={2} sx={{ padding: isMobileDevice() ? '1rem' : '1rem 8rem' }}>
                <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                    <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid item xs={isMobileDevice() ? 12 : 6}>
                            <ExSelect
                                ref={refPlantio}
                                id='plantio'
                                label='Plantio'
                                model={model_plantio}
                                onAfterChange={changePlantio}
                                isMulti
                            />
                        </Grid>
                        <Grid item xs={isMobileDevice() ? 6 : 3}>
                            <GroupBox title={'Visualizar'}>
                                <ExCheckBox
                                    id="checkview_embalagem"
                                    group="tipovisualizacao"
                                    type="radio"
                                    label="Embalagem"
                                    md={6}
                                    defaultValue={1}
                                    onClick={() => setViewPercent(false)}
                                />
                                <ExCheckBox
                                    id="checkview_porcentagem"
                                    group="tipovisualizacao"
                                    type="radio"
                                    label="Porcentagem"
                                    md={6}
                                    onClick={() => setViewPercent(true)}
                                />
                            </GroupBox>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{ backgroundColor: '#fff', margin: '10px 0px' }} />
                </Grid>
                <Grid item xs={12}>
                    <BarChart ref={refClassificacoes} />
                </Grid>
                <Grid item xs={12}>
                    <DataTable value={movClassificacao} originvalues={movClassificacao} disabledrefresh={true}>
                        {Columns(columns)}
                    </DataTable>
                </Grid>
            </Grid >
        </>
    )
})