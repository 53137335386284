import React from 'react'
import styled from 'styled-components';

const Label = styled.div`
    text-align: center;
    height: auto;
    width: auto;
    color: #fff;
    font-weight: bold;
    padding: 1px 5px;
    border-radius: 4px;
`

export interface LabelDataTableProps {
    color: string;
    children?: any;
}

const InternalLabelDataTable: React.FC<LabelDataTableProps> = (props) => {
    return (
        <Label
            style={{ backgroundColor: `${props.color}` }}
            {...props}
        />
    )
}

export const LabelDataTable = (value: any, color: any) => {
    return <InternalLabelDataTable color={color}>{value}</InternalLabelDataTable>
}