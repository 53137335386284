import styled from "styled-components";
import { ExButton } from "../../components/ExButton";

export const Container = styled.div`
    background-color: #2a323d;
`;

export const RowMain = styled.div`
    padding: 10px;
`;

export const RowWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const RowChild = styled.div`
    overflow: hidden;
    transition: max-height .3s ease-in-out;
    margin-left: 20px;
`;

export const ExpandButton = styled(ExButton)`
    display: flex;
    justify-content: center;
    margin-right: 8px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
`;

export const GrupoDescription = styled.span<{ haschilds: boolean }>`
    margin-left: ${(props) => (props.haschilds ? 0 : '38px')};
    margin-right: 15px;
`;
