import ReactApexChart from 'react-apexcharts';
import React from 'react';
import { COLOR_PRIMARY } from '../theme/styles';
import { isMobileDevice } from '../commons/utils';

export interface IProps {
    legendPosition?: any;
    removeLegend?: any;
    dataOffset?: any,
    height?: any;
    cellClick?: any;
    colors?: any;
    title?: any;
}

interface IState {
    options?: any;
    series?: any;
    height?: any;
}

class PieChart extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            series: [],
            height: props.height,
            options: {
                labels: [],
                theme: !props.colors ? {
                    monochrome: {
                        enabled: true,
                        color: COLOR_PRIMARY,
                        shadeTo: 'dark',
                        shadeIntensity: 0.75
                    }
                } : {},
                colors: props.colors,
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: (props.dataOffset !== undefined) ? props.dataOffset : - 5,
                            minAngleToShowLabel: 12
                        }
                    }
                },
                dataLabels: {
                    formatter(val: any, opts: any) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        const value = opts.w.globals.series[opts.seriesIndex]
                        return [name, val.toFixed(1) + '% (' + value + ')']
                    }
                },
                legend: {
                    position: props.legendPosition ? props.legendPosition : 'bottom',
                    show: props.removeLegend ? false : true,
                    labels: {
                        colors: '#fff'
                    }
                },
                stroke: {
                    width: 0.35
                },
                title: {
                    text: props.title,
                    align: 'center',
                    style: {
                        color: '#fff',
                        fontWeight: 500,
                        fontFamily: "Montserrat, Segoe UI",
                    }
                }
            }
        }
    }


    setSeries = (series: any[] | []) => {
        this.setState({
            series
        });
    }

    setLabels = (labels: any[] | []) => {
        this.setState({
            options: {
                ...this.state.options,
                labels,
            }
        });
    }

    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                width={'100%'}
                height={isMobileDevice() ? '400px' : '100%'}
                type="pie"
                align="center"
            />
        )
    }
}

export default PieChart;