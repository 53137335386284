import 'primeicons/primeicons.css';
import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css'
import 'primereact/resources/primereact.css';
import './styles.css';
import { MdOutlineRefresh } from "react-icons/md";
import React, { useState, useMemo, forwardRef, useImperativeHandle } from 'react';
import { DataTable as DataTablePrimeReact, DataTableProps } from 'primereact/datatable';
import { Input } from '../Input';
import styled from 'styled-components';
import { Grid, Tooltip } from '@mui/material';
import { ButtonFooter } from '../ExButtonDataTable';
import { locale, addLocale } from 'primereact/api';
import { ExControls } from '../ExControls';

// Adiciona a configuração do locale para o português
addLocale('pt', {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'Termina com',
    equals: 'Igual',
    notEquals: 'Diferente',
    noFilter: 'Sem filtro',
    lt: 'Menor que',
    lte: 'Menor ou igual a',
    gt: 'Maior que',
    gte: 'Maior ou igual a',
    dateIs: 'Data igual a',
    dateIsNot: 'Data diferente de',
    dateBefore: 'Data antes de',
    dateAfter: 'Data depois de',
    clear: 'Limpar',
    apply: 'Aplicar',
    matchAll: 'Corresponder a todos',
    matchAny: 'Corresponder a qualquer',
    addRule: 'Adicionar Condição',
    removeRule: 'Remover Condição',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolher',
    upload: 'Upload',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: 'Hoje',
    weekHeader: 'Sm',
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
    weak: 'Fraco',
    medium: 'Médio',
    strong: 'Forte',
    passwordPrompt: 'Digite uma senha',
    emptyMessage: 'Nenhum resultado encontrado',
    emptyFilterMessage: 'Nenhum resultado encontrado'
});

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
`

interface ExDataTableProps extends DataTableProps {
    refresh?: () => void;
    inputfilters?: any;
    originvalues: any;
    changefilter?: any;
    disabledrefresh?: any;
    maxRows?: any;
}

export const DataTable = forwardRef((props: ExDataTableProps, ref) => {
    locale('pt');

    const [data, setData] = useState<any[]>([]);
    const MAX_ROWS = props.maxRows ?? 50;

    const { refresh, inputfilters, originvalues, changefilter, disabledrefresh, ...dataTableProps } = props;

    function getData() {
        return data
    }

    function setCustomData(_data: any) {
        if (props.changefilter)
            props.changefilter(_data);

        setData(_data);
    }

    useImperativeHandle(ref, () => ({
        getData,
        setData
    }));

    const [globalFilter, setGlobalFilter] = useState<any>('');

    const filteredData = useMemo(() => {
        let _data: any = [];

        if (!props.value) {
            return _data;
        }

        _data = props.value.filter((item) =>
            Object.values(item).some((field) =>
                String(field).toLowerCase().includes(globalFilter.toLowerCase())
            )
        );

        return _data;
    }, [props.value, globalFilter]);

    const onValueChange = (e: any) => {
        setCustomData(e);
    };

    return (
        <DataTablePrimeReact
            {...dataTableProps}
            className="m-0"
            header={
                <>
                    <Header>
                        <Grid container spacing={2}>
                            {props.inputfilters &&
                                props.inputfilters.map((input: any, i: number) => (
                                    <Grid key={i} item xs={input.md}>
                                        <ExControls
                                            ref={input.ref}
                                            id={input.id}
                                            name={input.id}
                                            type={input.type}
                                            label={input.label}
                                            required={input.required}
                                            model={input.model}
                                            isMulti={input.isMulti}
                                            onAfterChange={(_newValue: any) => input.onChange(_newValue, props.originvalues)}
                                        />
                                    </Grid>
                                ))
                            }
                            <Grid item xs={12}>
                                <Input name="search" id="search" type="text" placeholder="Pesquisar" size="md" search onAfterChange={(value: any) => setGlobalFilter(value)} />
                            </Grid>
                        </Grid>
                    </Header>
                </>
            }
            paginatorRight={
                !props.disabledrefresh ?
                    <Tooltip title="Recarregar">
                        <ButtonFooter onClick={props.refresh}>
                            <MdOutlineRefresh />
                        </ButtonFooter>
                    </Tooltip> :
                    <></>
            }
            paginatorLeft={<></>}
            rowHover
            size="small"
            responsiveLayout="stack"
            breakpoint="960px"
            resizableColumns
            emptyMessage="Nenhum registro encontrado."
            rows={MAX_ROWS}
            paginatorTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
                    : "CurrentPageReport"
            }
            currentPageReportTemplate={
                Number(props.value?.length) > MAX_ROWS
                    ? "{first} à {last} de {totalRecords}"
                    : "{totalRecords} Registros"
            }
            expandedRowIcon="pi pi-angle-down"
            collapsedRowIcon="pi pi-angle-right"
            paginator={true}
            scrollHeight={'100%'}
            sortField={props.sortField}
            sortOrder={props.sortOrder ? props.sortOrder : 1}
            value={filteredData}
            onValueChange={onValueChange}
        />
    );
});
