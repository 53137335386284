import { forwardRef } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { Input } from "../Input";
import { ExSelect } from "../ExSelect";
import { ExCheckBox } from "../ExCheckBox";

export const ExFormLabel = styled(Form.Label)`
    margin-left: .375rem;
    margin-bottom: .275rem;  
    font-size: .785rem;
    font-weight: bold;
    font-family: "Montserrat";
`

export const FormFeedback = styled(Form.Control.Feedback)`
    margin: 0;
    font-size: .785rem;
    font-weight: 500;
`
interface ExControlsProps {
    id: any;
    name: any;
    type: any;
    label?: String;
    placeholder?: any;
    defaultValue?: any;
    feedback?: String;
    textfixed?: String;
    required?: any;
    md?: String;
    size?: any;
    onAfterChange?: any;
    search?: any;
    disabled?: any;
    options?: any;
    model?: any;
    validated?: any;
    maxLength?: any;
    autoFocus?: any;
    isMulti?: any;
    textarea?: any;
    rows?: any;
}

export const ExControls = forwardRef((props: ExControlsProps, ref: any) => {
    const { options, ...input } = props;

    return (
        <>
            {
                props.type === 'select' ?
                    <ExSelect
                        id={props.id}
                        ref={ref}
                        label={props.label}
                        options={props.options}
                        defaultValue={props.defaultValue}
                        md={props.md}
                        required={props.required}
                        model={props.model}
                        size={props.size}
                        validated={props.validated}
                        isMulti={props.isMulti}
                        onAfterChange={props.onAfterChange}
                        disabled={props.disabled}
                    /> :
                    props.type === 'check' ?
                        <ExCheckBox
                            ref={ref}
                            id={props.id}
                            label={props.label}
                            defaultValue={props.defaultValue}
                            md={props.md}
                            size={props.size}
                        /> :
                        <Input ref={ref} {...input} />
            }
        </>
    )
})