import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { ExSwitch } from "../../components/ExSwitch";
import { model_produto } from "../../models";

export const Produto = () => {
    const columns = [
        { field: 'proddescricao', header: 'Descrição' },
        { field: 'cultdescricao', header: 'Cultura' },
        { field: 'etapa', header: 'Etapa' },
        { field: 'uniddescricao', header: 'Unidade Padrão' },
        { field: 'unidmindescricao', header: 'Unidade Mínima' },
        { field: 'produsacategoria', header: 'Usa Categoria', body: (e: any) => <ExSwitch checked={e.produsacategoria === 1} /> },
        { field: 'produsatamanho', header: 'Usa Tamanho', body: (e: any) => <ExSwitch checked={e.produsatamanho === 1} /> },
        { field: 'prodativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_produto} columns={columns} sortField="proddescricao" />
    )
} 