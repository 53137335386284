import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_cultura } from "../../models";

export const Cultura = () => {
    const columns = [
        { field: 'cultid', header: 'Código', style: { width: '300px' } },
        { field: 'cultdescricao', header: 'Descrição' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_cultura} columns={columns} sortField="cultid" />
    )
}