import React, { useRef, useState } from "react";
import { arraysEqual, formatDate, formatDecimal, formatMonetary } from "../../../commons/utils";
import { ConsultaPadrao } from "../../../components/ConsultaPadrao";
import { model_produto, model_tipo_movimento, model_movimentos_por_periodo, model_plantio, model_movimentoestoque_campos } from "../../../models";
import { PDFReportVendasPorPeriodo } from "../../../reports/MovimentosPorPeriodo";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import styled from "styled-components";
import { PDFViewer } from "../../../components/PDFViewer";

const RowFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 16px;
    padding: 2px;
`
const ColFooter = styled.div`
    width: 150px;
    text-align: right;
`

export const MovimentosPorPeriodo = () => {
    const auth = useAuth();

    const refConsulta = useRef<any>();
    const refTipoMovimento = useRef<any>();
    const refProduto = useRef<any>();
    const refPlantio = useRef<any>();
    const refReport = useRef<any>();

    const [totals, setTotals] = useState({ total: 0, totalEmbalagens: 0, totalPesagem: 0, totalArea: 0, unitarioMedio: 0 });
    const [group, setGroup] = useState(false);

    let columnsbase = [
        { field: 'movemissao', header: 'Data', dataType: "date", body: (e: any) => formatDate(e.movemissao) },
        { field: 'tipmovdescricao', header: 'Tipo de Movimento' },
        { field: 'proddescricao', header: 'Produto' },
        { field: 'pldescricao', header: 'Plantio' },
        { field: 'culttamdescricao', header: 'Tamanho' },
        { field: 'cultcatdescricao', header: 'Categoria' },
    ];

    const columntransportador = [{ field: 'transdescricao', header: 'Transportador' }];

    const columnsqtde = [
        { field: 'qtdebaseunid', header: 'Embalagens', body: (e: any) => formatDecimal(e.qtdebaseunid), dataType: "numeric" },
        { field: 'qtdebase', header: 'Pesagem', body: (e: any) => formatDecimal(e.qtdebase), dataType: "numeric" },
    ];

    const columnspreco = [
        { field: 'precomedio', header: 'Unitário', body: (e: any) => formatMonetary(e.precomedio), dataType: "numeric" },
        { field: 'valortotal', header: 'Total', body: (e: any) => formatMonetary(e.valortotal), dataType: "numeric" },
    ]

    const [columns, setColumns] = useState<any[]>([...columnsbase, ...columntransportador, ...columnsqtde, ...columnspreco]);

    function sumTotais(_data: any) {
        const _total = _data?.reduce((total: any, item: any) => total + item.valortotal, 0);
        const _totalEmbalagens = _data?.reduce((total: any, item: any) => total + item.qtdebaseunid, 0);
        const _totalPesagem = _data?.reduce((total: any, item: any) => total + item.qtdebase, 0);

        const areaSet = new Set();
        const _totalArea = _data?.reduce((total: any, item: any) => {
            if (!areaSet.has(item.plid)) {
                areaSet.add(item.plid);
                return total + item.plarea;
            }
            return total;
        }, 0);

        setTotals({ total: _total, totalEmbalagens: _totalEmbalagens, totalPesagem: _totalPesagem, totalArea: _totalArea, unitarioMedio: _total / _totalPesagem ?? 0 });
    }

    async function showReport(_data: any) {
        const _report = await PDFReportVendasPorPeriodo(_data, columns, refTipoMovimento.current.getDescription());
        refReport.current.openModal(_report);
    }

    const headerfuncions = [
        { title: 'Relatório PDF', onClick: (data: any) => showReport(data) }
    ]

    function filterData(data: any, movimento: any, produto: any, plantio: any) {
        let _data: any = [];

        _data = data;

        if (movimento)
            _data = _data.filter((item: any) => item.tipmovcontador === movimento);

        if (produto)
            _data = _data.filter((item: any) => item.prodid === produto);

        if (plantio && (plantio.length > 0)) {
            const _plantios = new Set(plantio.map((item: any) => item.value));

            _data = _data.filter((item: any) => _plantios.has(item.plid));
        }

        refConsulta.current.setCustomData(_data);

        sumTotais(_data);
    }

    async function fetchShowTransportador(selectedValue: any) {
        if (!selectedValue)
            return true;

        try {
            const response: any = await auth.get(model_movimentoestoque_campos.api_get + '?tipmovcontador=' + selectedValue + '&movestcamponome=transportador');
            return response.data.length > 0;
        } catch (error) {
            console.log(error);
            return false;
        }
    }

    async function fetchProdTipMovimento(selectedValue: any) {
        try {
            const response: any = await auth.get(model_tipo_movimento.api_get + (selectedValue ? '?tipmovcontador=' + selectedValue : ''));
            const prod_tip_movimento: any[] = [];

            response.data.forEach((item: any) => {
                if (item.prodidentrada) {
                    prod_tip_movimento.push({ value: item.prodidentrada, label: item.produtoentrada });
                }

                if (item.prodidsaida) {
                    prod_tip_movimento.push({ value: item.prodidsaida, label: item.produtosaida });
                }
            });

            const new_columns = response.data[0].tipmovinformapreco === 1 ?
                (await fetchShowTransportador(selectedValue) ? [...columnsbase, ...columntransportador, ...columnsqtde, ...columnspreco] : [...columnsbase, ...columnsqtde, ...columnspreco]) :
                (await fetchShowTransportador(selectedValue) ? [...columnsbase, ...columntransportador, ...columnsqtde] : [...columnsbase, ...columnsqtde]);

            return { prod_tip_movimento, new_columns };
        } catch (error) {
            console.log(error);
            return { prod_tip_movimento: [], new_columns: [] };
        }
    }

    async function changeTipoMovimento(selected: any, data: any) {
        columnsbase = columnsbase.filter(col => !(col.field === 'tipmovdescricao'));

        setGroup(selected);

        const { prod_tip_movimento, new_columns } = await fetchProdTipMovimento(selected?.value);

        refProduto.current.setCustomOptions(prod_tip_movimento);

        if (selected && (prod_tip_movimento.length === 1)) {
            refProduto.current.setCustomValue(prod_tip_movimento[0]);
            filterData(data, selected?.value, prod_tip_movimento[0].value, refPlantio.current.getValue());
        } else
            filterData(data, selected?.value, undefined, refPlantio.current.getValue());

        if (!arraysEqual(columns, new_columns)) setColumns(new_columns);
    }

    async function changeProduto(selected: any, data: any) {
        filterData(data, refTipoMovimento.current.getValue(), selected?.value, refPlantio.current.getValue())
    }

    async function changePlantio(selected: any, data: any) {
        filterData(data, refTipoMovimento.current.getValue(), refProduto.current.getValue(), selected)
    }

    const groupFooter = () => {
        let showTotais = columns.some(column => column.field === 'valortotal');

        return (
            <React.Fragment>
                <td colSpan={12} style={{ padding: '20px' }}>
                    <RowFooter>
                        <ColFooter>Total Embalagens:</ColFooter>
                        <ColFooter>{formatDecimal(totals.totalEmbalagens)}</ColFooter>
                    </RowFooter>
                    <RowFooter>
                        <ColFooter>Total Pesagem:</ColFooter>
                        <ColFooter>{formatDecimal(totals.totalPesagem)}</ColFooter>
                    </RowFooter>
                    <RowFooter>
                        <ColFooter>Total Ha:</ColFooter>
                        <ColFooter>{formatDecimal(totals.totalArea)}</ColFooter>
                    </RowFooter>
                    <RowFooter>
                        <ColFooter>Pesagem por Ha:</ColFooter>
                        <ColFooter>{formatDecimal(totals.totalPesagem / totals.totalArea)}</ColFooter>
                    </RowFooter>
                    {showTotais && (
                        <>
                            <RowFooter>
                                <ColFooter>Unitário Médio:</ColFooter>
                                <ColFooter>{formatMonetary(totals.unitarioMedio)}</ColFooter>
                            </RowFooter>
                            <RowFooter>
                                <ColFooter>Total Geral:</ColFooter>
                                <ColFooter>{formatMonetary(totals.total)}</ColFooter>
                            </RowFooter>
                        </>
                    )}
                </td>
            </React.Fragment>
        )
    }

    function changeFilter(_data: any) {
        sumTotais(_data);
    }

    const filters = [
        { ref: refTipoMovimento, id: 'tipoMovimento', label: 'Tipo Movimento', type: 'select', model: model_tipo_movimento, md: 4, onChange: changeTipoMovimento },
        { ref: refProduto, id: 'produto', label: 'Produto', type: 'select', model: model_produto, md: 4, onChange: changeProduto },
        { ref: refPlantio, id: 'plantio', label: 'Plantio', type: 'select', model: model_plantio, md: 4, onChange: changePlantio, isMulti: true },
    ]

    return (
        <>
            <ConsultaPadrao
                ref={refConsulta}
                model={model_movimentos_por_periodo}
                columns={columns}
                sortField="movemissao"
                headerfuncions={headerfuncions}
                inputfilters={filters}
                groupcolumn={group && 'tipmovcontador'}
                groupfooter={groupFooter}
                changefilter={changeFilter}
                disabledrefresh={true}
            />
            <PDFViewer ref={refReport} />
        </>
    )
}