import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_campos } from "../../models";

export const Campos = () => {
    const columns = [
        { field: 'campnome', header: 'Código' },
        { field: 'campdescricao', header: 'Descrição' },
        { field: 'campmsgerro', header: 'Mensagem Erro' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_campos} columns={columns} sortField="campnome" />
    )
}