import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_produto_unidademedida } from "../../models";

export const ProdutoUnidadeMedida = () => {
    const columns = [
        { field: 'produnidid', header: 'Código' },
        { field: 'produniddescricao', header: 'Descrição' },
        { field: 'produnidativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_produto_unidademedida} columns={columns} sortField="produniddescricao" />
    )
}