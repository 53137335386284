import styled from "styled-components";

const FieldSetCustom = styled.fieldset`
    border: 1px solid #495057;
    padding: 2px 16px;    
    border-radius: 4px;
`
const LegendCustom = styled.legend`
    font-size: .785rem;
    font-weight: 600;
    width: auto;
    background-color: #212529;
    margin-left: .375rem;
    margin-bottom: .275rem;
    margin-top: 3px;
`
const Container = styled.div`
    display: flex;
`

interface GroupBoxProps {
    title: string;
    children: React.ReactNode;
}

export const GroupBox: React.FC<GroupBoxProps> = (props) => {
    return (
        <>
            <LegendCustom>{props.title}</LegendCustom>
            <FieldSetCustom>
                <Container>
                    {props.children}
                </Container>
            </FieldSetCustom>
        </>
    )
} 
