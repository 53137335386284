import { Grid } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { COLOR_PRIMARY } from '../../theme/styles';

const TitleCard = styled.div`
    color: #A3AED0;
    font-size: 14px;
    font-weight: 500;
`
const SubTitleCard = styled.div`
    margin-top: 3px;
    font-size: 12px;
`
const TextCard = styled.div`
    font-weight: 600;
`
const Container = styled(Grid)`
    box-shadow: 0px 2px 1px - 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 6px !important;
    padding: 20px;
    background: #2A323D;
`
const Wrapper = styled.div`
    display: flex;
    align-items: center;
`
const Image = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; 
`

export const ExCard = forwardRef((props: any, ref: any) => {
    const [value, setValue] = useState('-----');

    useImperativeHandle(ref, () => ({
        setValue
    }));

    return (
        <Container container>
            <Grid item xs={3} style={{ alignItems: 'center', display: 'flex' }}>
                <Image style={{ backgroundColor: 'rgba(0, 0, 0, .20)', fontSize: '34px', color: COLOR_PRIMARY }} >
                    {props.icon}
                </Image>
            </Grid>
            <Grid item xs={9}>
                <TitleCard>
                    <Wrapper>
                        {props.title}
                        {props.subtitle !== '' && <SubTitleCard>{props.subtitle}</SubTitleCard>}
                    </Wrapper>
                </TitleCard>
                <TextCard style={{ color: COLOR_PRIMARY, fontSize: props.fontSize ?? '22px' }}>{props.value ?? value}</TextCard>
                {props.footer &&
                    <SubTitleCard>{props.footer}</SubTitleCard>
                }
            </Grid>
        </Container>
    )
});