import { Grid } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { formatDataCompare, formatDate, formatDecimal, getLocalStorage, isMobileDevice, setLocalStorage } from "../../../commons/utils";
import { COLOR_PRIMARY } from "../../../theme/styles";
import { DataTable } from "../../../components/DataTable";
import { Columns, GridCard } from "..";
import { LabelDataTable } from "../../../components/LabelDataTable";
import { ExCard } from "../../../charts/Card";
import { GiTakeMyMoney } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";
import { TbCalendarX } from "react-icons/tb";
import { Input } from "../../../components/Input";
import styled from "styled-components";
import moment from "moment";
import BarChart from "../../../charts/Bar";

const RowFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 12px;
`
const ColFooter = styled.div`
    width: 150px;
    text-align: right;
`

const CR_SITUACAO_LABEL: { [key: number]: string } = {
    0: 'Aberto',
    1: 'Pago',
    2: 'Cancelado'
};

const CR_SITUACAO_COLOR: { [key: number]: string } = {
    0: '#55127c',
    1: '#086448',
    2: '#b44e13'
};

const columns = [
    { field: 'clinome', header: 'Cliente', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'emissao', header: 'Emissão', body: (e: any) => formatDate(e.emissao) },
    { field: 'crvencimento', header: 'Vencimento', body: (e: any) => formatDate(e.crvencimento) },
    { field: 'crultimopagamento', header: 'Último Pagamento', body: (e: any) => formatDate(e.crultimopagamento, true) },
    { field: 'formapagamento', header: 'Forma de Pagamento', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
    { field: 'crvalor', header: 'Valor Documento', body: (e: any) => formatDecimal(e.crvalor), dataType: 'numeric' },
    { field: 'valorrecebido', header: 'Valor Recebido', body: (e: any) => formatDecimal(e.valorrecebido), dataType: 'numeric' },
    { field: 'crsaldo', header: 'Valor em Aberto', body: (e: any) => formatDecimal(e.crsaldo), dataType: 'numeric' },
    {
        field: 'crsituacao', header: 'Situação',
        body: (e: any) => {
            const situacaoLabel = (e.crvencimento < formatDataCompare(new Date())) && e.crsituacao === 0 ? 'Vencido' : CR_SITUACAO_LABEL[e.crsituacao];
            const situacaoColor = (e.crvencimento < formatDataCompare(new Date())) && e.crsituacao === 0 ? '#b13939' : CR_SITUACAO_COLOR[e.crsituacao];
            return LabelDataTable(situacaoLabel, situacaoColor);
        },
        style: { width: '125px' }
    }
];

export const DashFinanceiroGeral = forwardRef((props: any, ref) => {
    const refcrveninicial = useRef<any>();
    const refcrvenfinal = useRef<any>();

    const refCrByVencimento = useRef<any>();
    const refTotalAberto = useRef<any>();
    const refTotalRecebido = useRef<any>();
    const refVencidos = useRef<any>();

    const [sortField, setSortField] = useState('clinome');
    const [sortOrder, setSortOrder] = useState<any>(1);
    const [dataGrid, setDataGrid] = useState<any>([]);
    const [crByVencimento, setCrByVencimento] = useState<any[]>([]);
    const [contasReceber, setContasReceber] = useState<any[]>([]);

    useImperativeHandle(ref, () => ({
        setData
    }));

    useEffect(() => {
        if (crByVencimento && crByVencimento.length > 0)
            populateChart(crByVencimento);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setData(_dataCrByVencimento: any, _dataCr: any) {
        setCrByVencimento(_dataCrByVencimento);
        setContasReceber(_dataCr);

        const ivencimento = getLocalStorage('dash_fin_venc_inicial') ? getLocalStorage('dash_fin_venc_inicial') : moment().format('YYYY-MM-01');

        prePopulate(_dataCrByVencimento, _dataCr, ivencimento)
    }

    function sumTotais(_data: any) {
        const totalCR = _data.reduce((acc: number, item: any) => acc + (item.crvalor || 0), 0);
        const totalAberto = _data.reduce((acc: number, item: any) => acc + (item.crsaldo || 0), 0);

        const totalVencido = _data.reduce((acc: number, item: any) => {
            const isVencido = ((item.crsituacao === 0) && (new Date() > new Date(item.crvencimento)));
            return acc + (isVencido ? item.crsaldo : 0);
        }, 0);


        return { totalAberto: totalAberto, totalRecebido: (totalCR - totalAberto), totalVencido: totalVencido };
    }

    function prePopulate(datachart: any, datagrid: any, ivencimento?: any, fvencimento?: any) {
        if (ivencimento) {
            datachart = datachart.filter((item: any) => formatDataCompare(item.crvencimento) >= ivencimento);
            datagrid = datagrid.filter((item: any) => formatDataCompare(item.crvencimento) >= ivencimento);
        }

        if (fvencimento) {
            datachart = datachart.filter((item: any) => fvencimento >= formatDataCompare(item.crvencimento));
            datagrid = datagrid.filter((item: any) => fvencimento > formatDataCompare(item.crvencimento));
        }

        setLocalStorage('dash_fin_venc_inicial', ivencimento ?? null);

        populateChart(datachart);
        populateGrid(datagrid);
    }

    function populateGrid(_data: any) {
        setDataGrid(_data);
        const _totais: any = sumTotais(_data);

        refTotalAberto.current.setValue(formatDecimal(_totais.totalAberto));
        refTotalRecebido.current.setValue(formatDecimal(_totais.totalRecebido));
        refVencidos.current.setValue(formatDecimal(_totais.totalVencido));
    }

    function populateChart(_data: any) {
        const dias = _data.map((item: any) => formatDate(item?.crvencimento));

        const valorreceber = _data.map((item: any) => parseFloat(item?.valorreceber || 0).toFixed(2));
        const valorrecebido = _data.map((item: any) => parseFloat(item?.valorrecebido || 0).toFixed(2));

        refCrByVencimento?.current.setOptions(dias, Math.max(...valorreceber), 'Contas a Receber (Por vencimento)',
            false, [COLOR_PRIMARY, '#06834f'], true, '12px');

        refCrByVencimento?.current.setSeries([
            { name: 'Valor a Receber', data: valorreceber, type: 'bar' },
            { name: 'Valor Recebido', data: valorrecebido, type: 'bar' },

        ]);
    }

    const groupFooter = (register: any, teste: any) => {
        const filteredData = dataGrid.filter((item: any) => item[sortField] === register[sortField]);
        const total = sumTotais(filteredData);

        return (
            <React.Fragment>
                <td colSpan={12} style={{ padding: '10px' }}>
                    <RowFooter>
                        <ColFooter>Total em Aberto:</ColFooter>
                        <ColFooter>{formatDecimal(total.totalAberto)}</ColFooter>
                    </RowFooter>
                    <RowFooter>
                        <ColFooter>Total Recebido:</ColFooter>
                        <ColFooter>{formatDecimal(total.totalRecebido)}</ColFooter>
                    </RowFooter>
                    <RowFooter>
                        <ColFooter>Total Vencido:</ColFooter>
                        <ColFooter>{formatDecimal(total.totalVencido)}</ColFooter>
                    </RowFooter>
                </td>
            </React.Fragment>
        );
    };

    function changeVencInicial(value: any) {
        prePopulate(crByVencimento, contasReceber, value, refcrvenfinal.current.getValue());

    }

    function changeVencFinal(value: any) {
        prePopulate(crByVencimento, contasReceber, refcrveninicial.current.getValue(), value)
    }


    return (
        <Grid container spacing={2} sx={{ padding: isMobileDevice() ? '1rem' : '1rem 8rem' }}>
            <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid item xs={isMobileDevice() ? 12 : 2}>
                        <Input ref={refcrveninicial} id="cr_venc_inicial" name="cr_venc_inicial" type="date" label="Vencimento Inicial" onAfterChange={changeVencInicial}
                            defaultValue={getLocalStorage('dash_fin_venc_inicial') ? getLocalStorage('dash_fin_venc_inicial') : moment().format('YYYY-MM-01')} />
                    </Grid>
                    <Grid item xs={isMobileDevice() ? 12 : 2}>
                        <Input ref={refcrvenfinal} id="cr_venc_final" name="cr_venc_final" type="date" label="Vencimento Final" onAfterChange={changeVencFinal} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <BarChart ref={refCrByVencimento} />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <GridCard md={4}>
                        <ExCard ref={refTotalAberto} title='Total em Aberto' icon={<GiReceiveMoney />} />
                    </GridCard>
                    <GridCard md={4}>
                        <ExCard ref={refTotalRecebido} title='Total Recebido' icon={<GiTakeMyMoney />} />
                    </GridCard>
                    <GridCard md={4}>
                        <ExCard ref={refVencidos} title='Total Vencidos' icon={<TbCalendarX />} />
                    </GridCard>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataTable
                    key={sortField}
                    value={dataGrid}
                    originvalues={dataGrid}
                    disabledrefresh={true}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    rowGroupMode={'subheader'}
                    groupRowsBy={sortField}
                    rowGroupFooterTemplate={groupFooter}
                    onSort={(e) => {
                        setSortOrder(e.sortOrder);
                        setSortField(e.sortField);
                    }}
                >
                    {Columns(columns)}
                </DataTable>
            </Grid>
        </Grid >
    )
})