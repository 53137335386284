import axios from 'axios';

const currentUrl = window.location.href;

export const Api = axios.create({
    baseURL: (
        currentUrl.includes('localhost') ||
        currentUrl.includes('127.0.0.1') ||
        currentUrl.includes('10.1.10.180')) ?
        'http://127.0.0.1:1206' : 'https://server.exaltsd.com.br:1206'
}) 
