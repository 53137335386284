import { Grid } from "@mui/material";
import { isMobileDevice } from "../../commons/utils";
import { Column } from "primereact/column";
import { Tab, Tabs } from "react-bootstrap";
import { DashEntradas } from "./Entradas";
import { DashClassificacoes } from "./Classificações";
import './styles.css';
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { toast } from "react-toastify";
import { DashVendas } from "./Saidas";
import FiltroCultura from "../FiltroCultura";
import { DashFinanceiroGeral } from "./FinanceiroGeral";

export const GridCard = (props: any) => {
    return (
        <Grid item xs={isMobileDevice() ? 12 : props.md ? props.md : 3}>
            {props.children}
        </Grid>
    )
}

export const Columns = (columns: any) => {
    return columns?.map((col: any, i: number) => (
        <Column
            key={col.field}
            field={col.field}
            align={isMobileDevice() || col.dataType === 'numeric' ? 'right' : 'left'}
            header={col.header}
            body={col.body}
            sortable={true}
            style={{ ...col.style, fontSize: '12px', marginBottom: `${i + 1 === columns.length ? '0px' : '-14px'}` }}
        />
    ));
};

export const Dashboard = () => {
    const auth = useAuth();

    const [culturaChecked, setCulturaChecked] = useState<any>({});
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const refEntrada = useRef<any>();
    const refClassificacao = useRef<any>();
    const refVenda = useRef<any>();
    const refFinanceiroGeral = useRef<any>();

    async function getMovimentos() {
        try {
            const entradaResponse: any = await auth.get('/v1/movimentosentrada');
            const classificacaoResponse: any = await auth.get('/v1/movimentosclassificacao');
            const saidaResponse: any = await auth.get('/v1/movimentossaida');
            const saldoResponse: any = await auth.get('/v1/movimentosaldoporproduto');
            const crByVencimento: any = await auth.get('/v1/crbyvencimento');
            const contasReceber: any = await auth.get('/v1/contasrecebercrm');

            refEntrada.current.setData(entradaResponse.data);
            refClassificacao.current.setData(entradaResponse.data, classificacaoResponse.data);
            refVenda.current.setData(entradaResponse.data, saidaResponse.data);
            refVenda.current.setSaldo(saldoResponse.data);
            refFinanceiroGeral.current.setData(crByVencimento.data, contasReceber.data);

            setData(entradaResponse.data);

            setIsLoading(false);
        } catch (error) {
            console.log(error);
            toast.error('Erro ao executar a operação.');
        }
    }

    useEffect(() => {
        getMovimentos();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Tabs style={{ padding: isMobileDevice() ? '1rem' : '.6rem 8rem' }}>
            <Tab eventKey="entradas" title="Entradas">
                {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                <DashEntradas ref={refEntrada} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
            </Tab>
            <Tab eventKey="classificacao" title="Classificação">
                {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                <DashClassificacoes ref={refClassificacao} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
            </Tab>
            <Tab eventKey="vendas" title="Vendas">
                {!isLoading && <FiltroCultura data={data} culturaChecked={culturaChecked} onChecked={(e: any) => setCulturaChecked(e)} />}
                <DashVendas ref={refVenda} cultura={culturaChecked} onChangeCultura={(e: any) => setCulturaChecked(e)} />
            </Tab>
            <Tab eventKey="financeirogeral" title="Financeiro Geral">
                <DashFinanceiroGeral ref={refFinanceiroGeral} />
            </Tab>
        </Tabs>
    );
}
