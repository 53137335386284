import Container from 'react-bootstrap/Container';
import { Navbar, Nav, Dropdown, Row } from 'react-bootstrap';
import Logo from '../../images/logoPequena.png'
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { AppRoutes } from '../../routes';
import { DropdownItem, DropdownToggle, ExNavDropdown, ExNavDropdownHeader, ExNavDropdownItem, ExNavItem, TitleToogle } from '../../components/ExNavbar';
import { FaBuildingWheat } from "react-icons/fa6";
import { FaBoxOpen } from "react-icons/fa";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { TbReportAnalytics } from "react-icons/tb";
import { Privilegio } from '../../contexts/AuthProvider/types';
import { isMobileDevice } from '../../commons/utils';
import { useEffect, useState } from 'react';

export const Principal = () => {
    const auth = useAuth();

    const [expanded, setExpanded] = useState<any>(false);

    async function alterUser(grupo: string, refresh: boolean) {
        if (auth.usuemail && auth.ususenha) {
            await auth.authenticate(auth.usuemail, auth.ususenha, grupo)
                .then(() => {
                    if (refresh)
                        window.location.reload()
                })
        }
    }

    function handleNavItemClick() {
        setExpanded(false);
    }

    useEffect(() => {
        if (auth.grupo)
            alterUser(auth.grupo, false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ height: '100vh' }}>
            <Navbar expand="lg" className="bg-body-tertiary" expanded={expanded}>
                <Container style={{ height: isMobileDevice() ? 'auto' : '60px' }}>
                    <Navbar.Brand href="/">
                        <img src={Logo} height={'40px'} alt='' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        {(auth.grupos) &&
                            <Nav className="me-auto">
                                <ExNavItem>
                                    <Dropdown>
                                        <DropdownToggle>
                                            <FaBuildingWheat style={{ position: 'absolute', left: 10, fontSize: '30px', top: 8 }} />
                                            <TitleToogle>Selecione o grupo:</TitleToogle>
                                            {auth.grupo.grudescricao}
                                        </DropdownToggle>

                                        <Dropdown.Menu>
                                            {
                                                auth.grupos.map((grupo: any, index: any) => (
                                                    <DropdownItem key={index} onClick={() => alterUser(grupo, true)}>{grupo.grudescricao}</DropdownItem>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </ExNavItem>
                            </Nav>
                        }
                        <Nav className="me-auto">
                            <ExNavItem to="/" onClick={handleNavItemClick}>Dashboard</ExNavItem>
                            {auth.privilegio === Privilegio.Admnistrador && <ExNavItem to="/usuario" onClick={handleNavItemClick}>Usuários</ExNavItem>}
                            {auth.privilegio === Privilegio.Admnistrador &&
                                <ExNavDropdown title="Grupos">
                                    <Row>
                                        <ExNavDropdownHeader icon={<VscGitPullRequestGoToChanges />} title={'Cadastros'}>
                                            <ExNavDropdownItem to="/grupo" onClick={handleNavItemClick}>Grupo</ExNavDropdownItem>
                                            <ExNavDropdownItem to="/grupoempresa" onClick={handleNavItemClick}>Empresa</ExNavDropdownItem>
                                        </ExNavDropdownHeader>
                                    </Row>
                                </ExNavDropdown>
                            }
                            {auth.useAppEstoques &&
                                <ExNavDropdown title="Estoques">
                                    <div style={{ width: '400px' }}>
                                        {auth.privilegio === Privilegio.Admnistrador &&
                                            <Row>
                                                <ExNavDropdownHeader icon={<FaBoxOpen />} title={'Produto'}>
                                                    <ExNavDropdownItem to="/cultura" onClick={handleNavItemClick}>Cultura</ExNavDropdownItem>
                                                    <ExNavDropdownItem to="/produtounidademedida" onClick={handleNavItemClick}>Unidade Medida</ExNavDropdownItem>
                                                    <ExNavDropdownItem to="/produto" onClick={handleNavItemClick}>Produto</ExNavDropdownItem>
                                                    <ExNavDropdownItem to="/produtounidadeconversao" onClick={handleNavItemClick}>Unidade Conversão</ExNavDropdownItem>
                                                </ExNavDropdownHeader>
                                            </Row>
                                        }
                                        {auth.privilegio === Privilegio.Admnistrador &&
                                            <Row>
                                                <ExNavDropdownHeader icon={<HiOutlineSwitchHorizontal />} title={'Movimentos'}>
                                                    <ExNavDropdownItem to="/movimentoestoquecampos" onClick={handleNavItemClick}>Campos</ExNavDropdownItem>
                                                    <ExNavDropdownItem to="/tipomovimento" onClick={handleNavItemClick}>Tipo Movimento</ExNavDropdownItem>
                                                </ExNavDropdownHeader>
                                            </Row>
                                        }
                                        <Row>
                                            <ExNavDropdownHeader icon={<TbReportAnalytics />} title={'Relatórios'}>
                                                <ExNavDropdownItem to="/relatorio/movimentosporperiodo" onClick={handleNavItemClick}>Movimentos Por Período</ExNavDropdownItem>
                                            </ExNavDropdownHeader>
                                        </Row>
                                    </div>
                                </ExNavDropdown>
                            }
                            {(auth.privilegio === Privilegio.Admnistrador) && (auth.useAppRequisicoes) &&
                                <ExNavDropdown title="Requisições">
                                    <div style={{ width: '400px' }}>
                                        <Row>
                                            <ExNavDropdownHeader icon={<VscGitPullRequestGoToChanges />} title={'Cadastros'}>
                                                <ExNavDropdownItem to="/camposrequisicao" onClick={handleNavItemClick}>Campos</ExNavDropdownItem>
                                                <ExNavDropdownItem to="/status" onClick={handleNavItemClick}>Status</ExNavDropdownItem>
                                            </ExNavDropdownHeader>
                                        </Row>
                                    </div>
                                </ExNavDropdown>
                            }
                            {auth.privilegio === Privilegio.Admnistrador &&
                                <ExNavDropdown title="Outros">
                                    <Row>
                                        <ExNavDropdownHeader icon={<VscGitPullRequestGoToChanges />} title={'Cadastros'}>
                                            <ExNavDropdownItem to="/campos" onClick={handleNavItemClick}>Campos</ExNavDropdownItem>
                                        </ExNavDropdownHeader>
                                    </Row>
                                </ExNavDropdown>
                            }
                        </Nav>
                        <Nav className="me-auto">
                            <ExNavItem onClick={() => auth.logout()}>Sair</ExNavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar >
            <AppRoutes />
        </div >
    )
}